import React from 'react';
import './WhatsappFAB.scss';
import { Button } from '@mui/material';

export default function WhatsappFAB() {

    return <>
        <Button
            className='whatsapp-fab'
            variant='text'
            href='https://wa.me/522214255638?text=Hola%2C%20me%20gustar%C3%ADa%20recibir%20informaci%C3%B3n%20de%20sus%20cursos.'
            target='_blank'
            size='medium'
        >
            <img src={require('../../../assets/icons/whatsapp-icon.png')} alt='whatsapp-icon-by-flaticon' className='icon'></img>
        </Button>
    </>

}